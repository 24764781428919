<template>
  <div class="item-content">
    <div class="title">
      <template v-if="title">
        {{ title }}
      </template>
      <template v-else>
        <slot name="title"></slot>
      </template>
    </div>
    <draggable
        v-bind="$attrs"
        :group="group"
        @start="drag=true"
        @end="drag=false"
        drag-class="dragClass"
    >
      <template #item="{element}">
        <div :class="title === element.newDomain? 'item' : 'select-item'" >
          <a-tooltip placement="top">
            <template #title>
              <span>原Account：{{ element.account }}</span>
            </template>
            <holder-outlined />
            {{ element.newDomain }}
          </a-tooltip>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { HolderOutlined } from "@ant-design/icons-vue";
export default {
  name: "DraggableBox",
  props: {
    title: {
      type: String,
      default: ""
    },
    group: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { draggable,HolderOutlined },
}
</script>

<style scoped lang="less">
.item-content {
  width: 270px;
  min-height: 70px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  position: relative;
  padding: 10px;
  margin-bottom: 5px;

  .title {
    position: absolute;
    top: -14px;
    margin-left: 5px;
    padding: 0 5px 0 5px;
    background-color: #ffffff;
  }

  .item {
    width: auto;
    height: 30px;
    display: table;
    position: relative;
    background-color: #FAFAFA;
    padding: 0 5px 0 5px;
    margin-bottom: 5px;
    line-height: 30px;
    cursor: move;

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 30px;
      background: #77CAED;
      border-radius: 2px;
      position: absolute;
      right: -8px;
      top: 0;
    }
  }
  .select-item {
    width: auto;
    height: 30px;
    display: table;
    position: relative;
    background-color: #E9F6FE;
    padding: 0 5px 0 5px;
    margin-bottom: 5px;
    line-height: 30px;
    cursor: move;

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 30px;
      background:#77CAED;
      border-radius: 2px;
      position: absolute;
      right: -8px;
      top: 0;
    }
  }
  .dragClass{
    background-color: #E9F6FE;
  }
}
</style>
