<template>
  <section id="accountList" class="org-list">
    <a-table
      ref="accountListRef"
      @change="handleTableChange"
      :columns="columns"
      :data-source="accountList"
      rowKey="id"
      :scroll="{
        x: 1200,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
      row-key="id"
      :row-selection="{
        columnTitle: ' ',
        selectedRows: selectedList,
        selectedRowKeys: selectedId,
        onSelect: onSelect,
        onChange: onSelectChange,
      }"
      :rowClassName="rowClassNameFunc"
    >
      <!--      :customRow="customRowFunc" -->
      <template #action="{ record }">
        <!--        <span :class="`link mr16 ${record.active_status == 0 ? 'underline' : 'disabled'}`" @click.stop="handleActiveClick(record)">启用</span>-->
        <!--        <span :class="`link mr16 ${record.active_status == 1 ? 'underline' : 'disabled'}`" @click.stop="handleDeactiveClick(record)">停用</span>-->
<!--        <span
          :class="`mr16 ${
            record.active_status === '1'
              ? 'operationLink underline'
              : 'operationLink underline disabled'
          }`"
          @click.stop="handleUpdateClick(record)"
          v-auth="['system:accountMaintain:edit']"
          >编辑</span
        >
        <span
          class="operationLink underline"
          @click.stop="handleChangeRecord(record)"
          v-auth="['system:accountMaintain:record']"
          >变更记录</span
        >-->
        <ActionBtn v-bind="record" :btnList="btnList" @FormOutlined="handleUpdateClick(record)" @HistoryOutlined="handleChangeRecord(record)" />
      </template>
    </a-table>
  </section>
  <!-- 正常由列表进的抽屉 -->
  <AccountDrawer
    v-model:visible="drawerVisible"
    :editRecord="editRecord"
    :drawerSelectOptions="drawerSelectOptions"
    :optType="optType"
    @update="doSearch"
    @showEditDrawer="showEditDrawer"
    @handleActive="handleActiveClick"
    @handleDeactive="handleDeactiveClick"
    @getAccountOptions="getNewAccountOptions"
  />
  <!-- 从详情页进入编辑时要再弹出一个抽屉 -->
  <AccountDrawer
    v-model:visible="editDrawerVisible"
    :editRecord="editRecord"
    :drawerSelectOptions="drawerSelectOptions"
    :optType="editOptType"
    @update="doSearch"
    @getTheAccountDetail="getTheAccountDetail"
    @getAccountOptions="getNewAccountOptions"
  />
  <!-- 停用触发的弹窗  -->
  <DeactiveModal
    :recordDate="deactiveData"
    v-model:visible="deactiveModalVisible"
    @close-modal="closeDeactiveModal"
    @save-modal="saveDeactiveModal"
  ></DeactiveModal>
  <!--  变更记录弹窗-->
  <ChangeRecordModal
    :zIndex="1001"
    v-model:visible="recordVisible"
    :id="rowDataId"
    title="Domain变更记录"
    @close-modal="closeRecordModal()"
  />
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  getCurrentInstance,
  toRefs,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import { useStore } from "vuex";
import AccountDrawer from "../components/AccountDrawer.vue";
import DeactiveModal from "./DeactiveModal";
import ChangeRecordModal from "@/views/home/BaseDataManagement/Account/components/ChangeRecordModal";
import { message } from "ant-design-vue";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '编辑',
    icon: 'FormOutlined',
    auth: ['system:accountMaintain:edit']
  },
  {
    name: '变更记录',
    icon: 'HistoryOutlined',
    auth: ['system:accountMaintain:record']
  },
]
export default defineComponent({
  name: "AccountList",
  components: { AccountDrawer, DeactiveModal, ChangeRecordModal, ActionBtn },
  props: {
    searchParams: {
      type: Object,
      defualt: () => {
        return {
          account: [],
          domain: [],
          pl: [],
          subPl: [],
          activeStatus: null,
          createDateBegin: null,
          createDateEnd: null,
          terminatedDateBegin: null,
          terminatedDateEnd: null,
        };
      },
    },
    drawerSelectOptions: Object,
  },
  emits: [
    "getAccountOptions",
    "handleSelectedDate",
    "getCaptiveAndTierList",
    "handleSelectedListDate",
    "get-total",
  ],
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const accountListRef = ref();
    const optType = ref("edit");
    const columns = reactive([
      {
        title: "Account ID",
        dataIndex: "account_code",
        key: "account_code",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Domain ID",
        dataIndex: "domain_code",
        key: "domain_code",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Domain",
        dataIndex: "domain",
        key: "domain",
        width: 150,
        ellipsis: true,
      },
      {
        title: "P&L",
        dataIndex: "pl",
        key: "pl",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Sub_P&L",
        dataIndex: "sub_pl",
        key: "sub_pl",
        width: 150,
        ellipsis: true,
        sorter: true,
        sortOrder: false,
      },
      {
        title: "Create Date",
        dataIndex: "create_date",
        key: "create_date",
        width: 200,
        ellipsis: true,
        sorter: true,
        sortOrder: false,
      },
      {
        title: "Terminated Date",
        dataIndex: "terminated_date",
        key: "terminated_date",
        width: 250,
        ellipsis: true,
        sorter: true,
        sortOrder: false,
      },
      {
        title: "Active",
        dataIndex: "active_status",
        key: "active_status",
        ellipsis: true,
        width: 150,
        customRender: ({ text }) => (text == 0 ? "N" : text == 1 ? "Y" : text),
      },
    ]);
    const drawerVisible = ref(false);
    if (store.state.app.perms.includes("system:accountMaintain:operation")) {
      columns.push({
        title: "操作",
        key: "operation",
        fixed: "right",
        width: 100,
        slots: { customRender: "action" },
      });
    }
    const sortObj = { field: null, order: null };
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
      },
      onShowSizeChange: (current, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
      },
    });
    const accountList = ref();
    let searchCondition = {};
    const tableHeight = ref(0);
    const getSearchParams = () => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...props.searchParams,
        sort: {
          ...sortObj,
        },
      };
      return params;
    };
    const doSearch = () => {
      const params = getSearchParams();
      $api.getAccountMaintainList(params).then((res) => {
        pagination.total = res.data.total;
        accountList.value = res.data.resource;
        emit("get-total",res.data.total)
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };
    const selectedState = reactive({
      selectedId: [],
      selectedList: [],
    });
    const onSelectChange = (selectedRowKeys) => {
      selectedState.selectedId = selectedRowKeys;
      emit("handleSelectedDate", selectedState.selectedId);
    };
    const onSelect = (record, selected) => {
      if (selected) {
        selectedState.selectedList.push(record);
      } else {
        const delIndex = selectedState.selectedList.findIndex((val) => {
          return val.id === record.id;
        });
        selectedState.selectedList.splice(delIndex, 1);
      }
      emit("handleSelectedListDate", selectedState.selectedList);
    };

    const getNewAccountOptions = () => {
      emit("getAccountOptions");
    };
    const tableWidth = window.innerWidth;

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    const editRecord = ref({});
    const handleUpdateClick = (record) => {
      optType.value = "edit";
      editRecord.value = record;
      drawerVisible.value = true;
    };

    // 变更记录
    const recordVisible = ref(false);
    const rowDataId = ref("");

    const handleChangeRecord = (record) => {
      rowDataId.value = record.id;
      recordVisible.value = true;
    };

    const closeRecordModal = () => {
      recordVisible.value = false;
    };

    const getTheAccountDetail = (id) => {
      $api.getAccountDetailData({ id: id }).then((res) => {
        editRecord.value = res.data[0];
        Object.assign(editRecord.value, { flag: "detail" });
      });
    };

    const deactiveModalVisible = ref(false);

    const handleActiveClick = (record, source) => {
      $api
        .activeAccountMaintain({
          id: record.id,
        })
        .then((res) => {
          if (res.code === 200) {
            // drawerVisible.value = false;
            if (source === "detail") {
              getTheAccountDetail(record.id);
            }
            doSearch();
            message.success("成功");
          } else {
            message.error("失败");
          }
        });
    };

    const deactiveData = ref({});
    let sourceFlag = "";
    const handleDeactiveClick = (record, source) => {
      sourceFlag = source;
      deactiveData.value = {
        id: record.id,
        createDate: record.create_date,
        terminatedDate: record.terminated_date,
      };
      deactiveModalVisible.value = true;
    };

    const closeDeactiveModal = () => {
      deactiveModalVisible.value = false;
    };

    const saveDeactiveModal = (data) => {
      $api
        .deactiveAccountMaintain({
          id: data.id,
          terminatedDate: data.terminatedDate,
        })
        .then((res) => {
          if (res.code === 200) {
            // 调用详情接口
            if (sourceFlag === "detail") {
              getTheAccountDetail(data.id);
            }
            doSearch();
            message.success("成功");
          } else {
            message.error("失败");
          }
          closeDeactiveModal();
        });
    };
    // 排序
    const handleTableChange = (pag, filter, sorter) => {
      columns[2].sortOrder = false;
      columns[3].sortOrder = false;
      columns[4].sortOrder = false;
      columns.forEach((item) => {
        if (item.dataIndex === sorter.field) {
          item.sortOrder = sorter.order;
        }
      });
      sortObj.field = sorter.field;
      sortObj.order = sorter.order;
      doSearch();
    };

    const resetAll = () => {
      pagination.current = 1;
      sortObj.field = null;
      sortObj.order = null;
      columns[2].sortOrder = false;
      columns[3].sortOrder = false;
      columns[4].sortOrder = false;
      resetSelectState();
    };

    // 重置选中项
    const resetSelectState = () => {
      for (let key in selectedState) {
        selectedState[key] = [];
      }
      emit("handleSelectedListDate", selectedState.selectedList);
      emit("handleSelectedDate", selectedState.selectedId);
    };

    onMounted(() => {
      getSize();
      // doSearch();
    });
    const { proxy } = getCurrentInstance();
    proxy.$EventBus.on("change-select-height", () => {
      setTimeout(() => {
        tableHeight.value = useTableHeight();
      });
    });

    // 点击列表获取详情
    // const customRowFunc = (record) => {
    //   return {
    //     onClick: () => {
    //       optType.value = "detail"
    //       editRecord.value = record;
    //       drawerVisible.value = true;
    //     },
    //   };
    // };

    const editDrawerVisible = ref(false);
    const editOptType = ref("");
    const showEditDrawer = () => {
      editOptType.value = "edit";
      // @todo
      // emit("getCaptiveAndTierList")
      editDrawerVisible.value = true;
    };

    const rowClassNameFunc = (record) => {
      if (record.active_status === '0') {
        return "deactivate-row";
      }
      return null;
    };

    return {
      optType,
      resetAll,
      handleTableChange,
      drawerVisible,
      accountListRef,
      columns,
      pagination,
      accountList,
      searchClick,
      tableHeight,
      tableWidth,
      doSearch,
      handleUpdateClick,
      handleActiveClick,
      handleDeactiveClick,
      editRecord,
      // customRowFunc,
      deactiveModalVisible,
      closeDeactiveModal,
      saveDeactiveModal,
      deactiveData,
      editDrawerVisible,
      showEditDrawer,
      editOptType,
      getSearchParams,
      getTheAccountDetail,
      getNewAccountOptions,
      getSize,
      ...toRefs(selectedState),
      onSelectChange,
      handleChangeRecord,
      recordVisible,
      closeRecordModal,
      rowDataId,
      onSelect,
      resetSelectState,
      btnList,
      rowClassNameFunc,
    };
  },
});
</script>
<style scoped lang="less">
.org-list {
  display: flex;
  width: 100%;

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    background: #f4f4f4;
  }
}
:deep(.ant-table-body) {
  .deactivate-row {
    background: #f4f4f4 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #f4f4f4 !important;
    }
  }
}
</style>
