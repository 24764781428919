<template>
  <a-drawer :maskClosable="true" :closable="true" class="merge-drawer" :width="'65vw'"
            :title="titleText" :visible="visible" @close="handleCancel"
            :zIndex="900">
    <div class="content">
      <div class="steps">
        <div class="step">
          <a-steps :current="current" labelPlacement="vertical">
            <a-step title="合并Account" />
            <a-step title="合并Domain" />
            <a-step title="确定结果" />
            <a-step title="设置Terminated Date" />
          </a-steps>
        </div>
      </div>
      <div class="infos">
        <div class="info">
          <QuestionCircleOutlined :style="{ color: '#3182CE', 'margin-right': '5px'}" />
          <span v-show="current === 0">请先选择需要合并的Account，并标出合并后的Account</span>
          <span v-show="current === 1">您可随意拖动Domain进行合并</span>
          <span v-show="current === 2">映射关系</span>
          <span v-show="current === 3">对Terminated Date进行修改或删除</span>
        </div>
      </div>
    </div>
    <div>
      <OneMergingAccount
          v-show="current === 0"
          :visible="oneVisible"
          @selectAccountData="getSelectAccountData"
          :accountOpsData="accountOpsData"
      ></OneMergingAccount>
      <TwoMergeDomain
          v-if="current === 1"
          :selectedAccount="oneMergingAccountData"
          :current="current"
          :isgetDomain="isgetDomain"
          @mergeDomainData="getMergeDomainData"
      ></TwoMergeDomain>
      <ThreeMappingRelation
          v-if="current === 2"
          :mergeDomainData="mergeDomainData"
      ></ThreeMappingRelation>
      <FourTerminatedDate
          v-if="current === 3"
          :terminatedDate="mergeDomainData"
          @terminatedDateEmit="getTerminatedDate"
      ></FourTerminatedDate>
    </div>
    <div
        :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-space class="footer">
      <a-button type="primary" ghost @click="handleCancel">取消</a-button>
      <a-button type="primary" v-show="!(current === 0)" @click="handlePerv">上一步</a-button>
      <a-button type="primary" v-if="!(current === 3)" @click="handleNext">下一步</a-button>
      <a-button type="primary" v-else @click="handleSave">提交</a-button>
      </a-space>
    </div>
<!--    <template #footer>-->
<!--      <a-space class="footer">-->
<!--        <a-button type="primary" ghost @click="handleCancel">取消</a-button>-->
<!--        <a-button type="primary" v-show="!(current === 0)" @click="handlePerv">上一步</a-button>-->
<!--        <a-button type="primary" v-if="!(current === 3)" @click="handleNext" :disabled="oneMergingAccountData.length < 2">下一步</a-button>-->
<!--        <a-button type="primary" v-else @click="handleSave">提交</a-button>-->
<!--      </a-space>-->
<!--    </template>-->
  </a-drawer>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import OneMergingAccount from "@/views/home/BaseDataManagement/Account/components/OneMergingAccount";
import TwoMergeDomain from "@/views/home/BaseDataManagement/Account/components/TwoMergeDomain";
import ThreeMappingRelation from "@/views/home/BaseDataManagement/Account/components/ThreeMappingRelation";
import FourTerminatedDate from "@/views/home/BaseDataManagement/Account/components/FourTerminatedDate";
import {useGlobalPropertyHook} from "@/hooks/common";
import { notification } from 'ant-design-vue';

export default defineComponent({
  name: "NewMergingModal",
  emits: ["update", "handleSaveNewMergedDomian"],
  components: {FourTerminatedDate, ThreeMappingRelation, TwoMergeDomain, OneMergingAccount, QuestionCircleOutlined},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup(props, {emit}) {
    const { $api } = useGlobalPropertyHook();
    const titleText = ref("合并");
    let current = ref(0);

    const oneVisible = ref(true)

    const handleCancel = () => {
      localStorage.removeItem('Account-Data')
      oneVisible.value = true
      let search = 'cancel'
      emit("handleSaveNewMergedDomian",search)
    };
    const handlePerv = () => {
      current.value--
    }
    const handleNext = () => {
      if(current.value === 0) {
        if(oneMergingAccountData.value.oldAccountIdList.length < 2 || oneMergingAccountData.value.oneMergingAccountSelect.length === 0){
          message.error('请先选择需要合并的Account')
        } else {
          current.value++
        }
      } else {
        current.value++
      }
    }
    const handleSave = () => {
      oneMergingAccountData.value.oldAccountIdList = oneMergingAccountData.value.oldAccountIdList.filter(item => item !== oneMergingAccountData.value.newAccountId)
      const params = {
        mergeAccount: oneMergingAccountData.value,
        mergeDomain: mergeDomainData.value,
        terminatedDate: selectTerminatedDate.value
      }
      console.log(params)
      $api.mergeDomainData(params).then((res) => {
        if (res.data) {
          notification.error({
            message: '错误',
            description:res.data.join(`\n`),
            dangerouslyUseHTMLString: true,
            style: {
              width:'300px',
              marginLeft: `${335 - 900}px`,
              background:'#FFF2F0',
              whiteSpace: 'pre-wrap',
            },
          });
        } else {
          let search = 'save'
          message.success("成功");
          emit("handleSaveNewMergedDomian",search)
        }
      });
    };

    const oneMergingAccountData = ref({
      oldAccountIdList:[],
      oneMergingAccountSelect:[],
      newAccountId:''
    })
    const getSelectAccountData = (data1,data2,data3) => {
      oneMergingAccountData.value.oldAccountIdList = data1
      oneMergingAccountData.value.oneMergingAccountSelect = data2
      oneMergingAccountData.value.newAccountId = data3
    }

    const mergeDomainData = ref([])
    const getMergeDomainData = (data) => {
      mergeDomainData.value = data
    }

    const selectTerminatedDate = ref([])
    const getTerminatedDate = (data) => {
      selectTerminatedDate.value = data
    }
    const accountOpsData = ref([])
    const getAccountOps = () => {
      $api.getAllAccountOpt().then((res) => {
        accountOpsData.value = res.data;
      })
    }
    watch(
        () => props.visible,
        (value) => {
          if (value) {
            current.value = 0
            oneVisible.value = false
            getAccountOps()
          }
        },
    );

    watch(
      () => oneMergingAccountData.value.oldAccountIdList,
      (val) => {
          if(val){
            isChangeAccount.value = true
          }
        },
    )
    const isChangeAccount = ref(false)
    const isgetDomain = ref(false)
    watch(
      () => current.value,
      (newvalue, odlvalue) => {
          if(odlvalue === 0 && newvalue === 1 && isChangeAccount.value === true){
            isgetDomain.value = true
            isChangeAccount.value = false
          } else {
            isgetDomain.value = false
          }
          oneVisible.value = true
        },
        { deep: true,  flush: 'sync' }
    )

    onMounted(async () => {
    });
    return {
      titleText,
      handleCancel,
      handleSave,
      current,
      handlePerv,
      handleNext,
      oneMergingAccountData,
      getSelectAccountData,
      mergeDomainData,
      getMergeDomainData,
      selectTerminatedDate,
      getTerminatedDate,
      isgetDomain,
      isChangeAccount,
      oneVisible,
      getAccountOps,
      accountOpsData,
    };
  },
});
</script>

<style lang="less" scoped>
.merge-drawer {
  .content {
    .steps{
      display: flex;
      width: 100%;
      justify-content: center;
      .step {
        width: 60%;
        :deep(.ant-steps-item-title) {
          font-size: 0.6vw !important;
        }
        :deep(.ant-steps-item-wait .ant-steps-item-icon){
          background-color:#E5E5E5;
          .ant-steps-icon {
            color:#999999
          }
        }
        :deep(.ant-steps-item-process .ant-steps-item-icon){
          background-color: #3182CE;
          .ant-steps-icon {
            color: #FFFFFF
          }
        }
        :deep(.ant-steps-item-finish .ant-steps-item-icon){
          background-color:#77CAED;
          .ant-steps-icon {
            color: #FFFFFF
          }
        }
        :deep(.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title){
          color:#E5E5E5
        }
        :deep(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title){
          color:#3182CE
        }
        :deep(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title){
          color:#77CAED
        }
      }
    }

    .infos {
      background-color: #F7FAFC;
      border: none;
      .info{
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 24px;
      }
    }

  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
.message-class{
  color:red;
  whiteSpace: 'pre-wrap',
}
</style>
