<template>
  <div class="One-MergingAccount">
    <div class="display: flex">
      <a-form layout="vertical" :model="selectAccountData" ref="formRef" style="width: 50%;">
        <a-form-item label="选择Account:" name="selectAccount">
          <SelectWithAll
              class="form-item-inline"
              v-model:value="selectAccountData"
              placeholder="请选择"
              mode="multiple"
              showArrow
              allowClear
              :maxTagCount="1"
              :filterOption="filterOption"
              @change="accountChange"
          >
            <a-select-option
                v-for="(item, index) in accountOps"
                :key="index.id"
                :value="item.id"
            ><span :title="item.account">{{ item.account }}</span>
            </a-select-option>
          </SelectWithAll>
        </a-form-item>
      </a-form>
    </div>
    <div class="main-padding">
      <div style="margin-bottom: 5px;">Account列表({{selectAccountData.length}})</div>
      <div :style="`background-color: ${ selectAccountData.length > 0 ? '#fafafa' : 'none'}`">
        <a-radio-group v-model:value="mergeAccount" style="width: 100%; padding: 5px 0 5px 0" @change="radioChange">
          <div v-for="(item,index) in accountListOps" :key="item" style="padding:5px 15px 5px 10px;">
            <a-radio :value="item">
              {{ item }}
            </a-radio>
            <DeleteOutlined :style="{ color: '#f11717',float: 'right'}" @click="delAccount(index)"/>
          </div>
        </a-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import { DeleteOutlined } from "@ant-design/icons-vue";

export default {
  name: "OneMergingAccount",
  components: {DeleteOutlined},
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    accountOpsData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["selectAccountData"],
  setup(props, { emit }) {
    const formRef = ref();
    const selectAccountData = ref([])
    const accountOps = computed(() => props.accountOpsData)

    const accountListOps = computed(() =>
        accountOps.value.filter(item => selectAccountData.value.indexOf(item.id) !== -1).map(item => item.account)
    );
    const mergeAccount = ref([])

    const delAccount = (index) => {
      selectAccountData.value.splice(index,1)
    }
    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };
    const radioChange = () => {
      let a = accountOps.value.filter(item => item.account === mergeAccount.value)[0].id
      emit("selectAccountData", selectAccountData.value,mergeAccount.value,a);
    }
    const accountChange = () => {
      let a = ''
      if(mergeAccount.value.length > 0) {
        if (selectAccountData.value.includes(mergeAccount.value[0])) {
          a = accountOps.value.filter(item => item.account === mergeAccount.value)[0].id
        } else {
          mergeAccount.value = []
        }
      }
      emit("selectAccountData", selectAccountData.value,mergeAccount.value,a);
    }
    watch(
        () => props.visible,
        (val) => {
          //debugger
          if(val === false) {
            selectAccountData.value = []
          }
        },
        {immediate:true,deep:true}
    )

    return {
      formRef,
      selectAccountData,
      accountOps,
      accountListOps,
      mergeAccount,
      delAccount,
      radioChange,
      filterOption,
      accountChange,
    }
  }
}
</script>

<style scoped>
.One-MergingAccount{
  margin: 24px 0 52px 0;
}
.main-padding{
  padding: 0 24px;
}
</style>
