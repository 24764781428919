<template>
  <a-drawer :maskClosable="true" :closable="true" wrapClassName="account-drawer" width="38.5vw"
    :title="titleText[optType]" :visible="visible" @update:visible="(val) => $emit('update:visible', val)"
    :zIndex="900">
    <slot></slot>
    <div class="forms mt24">
      <a-form class="form" layout="vertical" ref="formRef1" :rules="rules" :model="formState">
        <a-form-item label="Account ID" name="accountId" v-if="optType !== 'add'">
          <a-input :disabled="true" class="item" placeholder="请输入" allow-clear @change="() => { }"
            v-model:value="formState.accountCode" :maxlength="36" />
        </a-form-item>
        <a-form-item label="Account" name="account">
          <a-input :disabled="optType !== 'add'" class="item" placeholder="请输入" allow-clear
            @blur="changeAccountCode()" v-model:value="formState.account" :maxlength="36" />
        </a-form-item>
        <a-form-item label="Domain ID" name="domiantId" v-if="optType !== 'add'">
          <a-input :disabled="true" class="item" placeholder="请输入" allow-clear @change="() => { }"
            v-model:value="formState.domainCode" :maxlength="36" />
        </a-form-item>
        <a-form-item label="Domain" name="domain">
          <a-input class="item" placeholder="请输入" allow-clear @change="() => { }" v-model:value="formState.domain"
            :maxlength="50" />
        </a-form-item>
        <a-form-item label="P&L" name="pl">
          <a-select :disabled="optType === 'detail' || optType === 'edit' || plsubpldisabled" class="item"
            placeholder="请选择" allow-clear @change="changePl()" v-model:value="formState.pl">
            <a-select-option v-for="(item, index) in plOptionList" :key="index" :value="item"><span :title="item">{{
              item
            }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Sub_P&L" name="subPl">
          <a-select :disabled="optType === 'detail' || optType === 'edit' || plsubpldisabled" class="item"
            placeholder="请选择" allow-clear @change="() => { }" v-model:value="formState.subPl">
            <a-select-option v-for="(item, index) in subPlOptionList" :key="index" :value="item"><span :title="item">{{
              item
            }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Captive" name="captive">
          <a-select :disabled="optType === 'detail'" class="item" placeholder="请选择" allow-clear @change="() => { }"
            v-model:value="formState.captive">
            <a-select-option v-for="(item, index) in captiveList" :key="index" :value="item"><span :title="item">{{
              item
            }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Tier" name="tier">
          <a-select :disabled="optType === 'detail'" class="item" placeholder="请选择" allow-clear @change="() => { }"
            v-model:value="formState.tier">
            <a-select-option v-for="(item, index) in tierList" :key="index" :value="item"><span :title="item">{{
              item
            }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Create Date" name="createDate" v-if="optType !== 'add'">
          <a-date-picker class="item" allow-clear type="date" placeholder="选择日期" valueFormat="YYYY-MM-DD"
            :disabled="true" v-model:value="formState.createDate" />
        </a-form-item>
        <a-form-item label="Active" name="status" class="form-item-inline">
          <a-select :disabled="true" class="item" v-model:value="formState.active_status" placeholder="请选择">
            <a-select-option key="Y" value="1">Y</a-select-option>
            <a-select-option key="N" value="0">N</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
    <div class="header bottom-btn">
      <a-space>
        <a-button type="primary" ghost @click="handleCancel"
                  v-if="optType === 'add' || optType === 'edit'">取消</a-button>
        <a-button type="primary" v-if="optType === 'detail' && editRecord.active_status === '0'"
                  @click="handleActive()">启用</a-button>
        <a-button type="primary" v-if="optType === 'detail' && editRecord.active_status === '1'"
                  @click="handleDeactive()">停用</a-button>
        <a-button type="primary" v-if="optType === 'detail'" @click="showRecord">变更记录</a-button>
        <a-button type="primary" v-if="optType === 'detail'" @click="showEdit">编辑</a-button>
        <a-button type="primary" v-if="optType === 'add' || optType === 'edit'" @click="handleSave()">保存</a-button>
      </a-space>
    </div>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  </a-drawer>
  <ChangeRecordModal :zIndex="1001" v-model:visible="recordVisible" :id="formState.id" title="查看Account编辑记录"
    @close-modal="closeModal()" />

</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import moment from "moment";
import ChangeRecordModal from "./ChangeRecordModal.vue";
import { message } from "ant-design-vue";
import ModalBox from '@/components/ModalBox'
export default defineComponent({
  name: "AccountDrawer",
  emits: ["update", "showEditDrawer", "handleActive", "handleDeactive",
    "getTheAccountDetail", "getAccountOptions", "handleAddTbd"],
  components: {
    ChangeRecordModal,
    ModalBox
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    optType: {
      type: String,
      default: "",
    },
    drawerSelectOptions: Object,
    editRecord: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const titleText = ref({
      add: "新增Account",
      edit: "编辑Account",
      detail: "详情",
    });
    const { $api } = useGlobalPropertyHook();
    const formRef1 = ref();
    const formState = reactive({
      account: "",
      domain: "",
      accountCode: "",
      domainCode: "",
      subPl: undefined,
      pl: undefined,
      captive: undefined,
      tier: undefined,
      active_status: "1",
      id: "",
    });

    const resetForm = () => {
      formRef1?.value?.resetFields();
    };

    const updateFormState = (source) => {
      //重命名
      const { sub_pl: subPl, create_date: createDate, account_code: accountCode, domain_code: domainCode } = source
      Object.assign(source, { subPl, createDate, accountCode, domainCode })
      Object.assign(formState, source)
    };

    watch(
      () => props.visible,
      (value) => {
        if (value) {
          // 每次抽屉打开都清空表单校验信息
          formRef1?.value?.resetFields();
          updateFormState(props.editRecord)
          if (props.optType === 'add') {
            // getcCurrentDate()
            formState.active_status = '1';
          }
        } else {
          resetForm();
        }
      },
      { deep: true }
    );

    const plOptionList = ref([]);
    const subPlOptionList = ref([]);
    const getPlAndSubPlList = () => {
      $api.getPlAndSubPlList().then(res => {
        plOptionList.value = res.data.pl
        subPlOptionList.value = res.data.subPl
      })
    }
    const changePl = () => {
      formState.subPl = undefined;
    }
    const recordVisible = ref(false);

    const rulusInfo = {
      account: [
        {
          required: true,
          trigger: "blur",
          validator: async (rule, value) => {
            if (value.trim()) {
              return Promise.resolve();
            } else {
              return Promise.reject("Account不能为空");
            }
          },
        },
        {
          pattern: /^[0-9a-zA-Z_& -]{1,}$/,
          message: "只能输入字母、数字、特殊字符（&, _, -, 空格）",
          trigger: "blur",
        },
      ],
      domain: [
        {
          required: true,
          trigger: "blur",
          validator: async (rule, value) => {
            if (value.trim()) {
              return Promise.resolve();
            } else {
              return Promise.reject("Domain不能为空");
            }
          },
        },
        {
          pattern: /^[0-9a-zA-Z_& /'-]+$/,
          message: "只能输入字母、数字、特殊字符（&, _, -, ', /, 空格）",
          trigger: "blur",
        },
      ],
      pl: [{ required: true, message: "P&L不能为空", trigger: "change" }],
      subPl: [{ required: true, message: "Sub_P&L不能为空", trigger: "change" }],
      captive: [{ required: true, message: "Captive不能为空", trigger: "change" }],
      tier: [{ required: true, message: "Tier不能为空", trigger: "change" }],
    }

    const rules = ref(rulusInfo);

    // 详情没有必填校验
    // watch(
    //   () => props.optType,
    //   (value) => {
    //     rules.value = value !== "detail" ? rulusInfo : {}
    //   },
    //   { deep: true}
    // );

    const handleCancel = () => {
      emit("update:visible", false);
    };
    const returnedData = ref({})
    //ccountCode查询P&L,Sub_P&L
    const plsubpldisabled = ref(false)
    const changeAccountCode = () => {
      if (formState.account) {
        $api.getPlAndSubPl(formState.account).then(res => {
          console.log(res);
          if (res.data) {
            formState.pl = res.data.pl
            formState.subPl = res.data.subPl
            plsubpldisabled.value = true
          } else {
            formState.pl = ''
            formState.subPl = ''
            plsubpldisabled.value = false
          }
        })
      } else {
        formState.pl = ''
        formState.subPl = ''
        plsubpldisabled.value = false
      }
    }
    const handleSave = () => {
      Promise.all([formRef1.value.validate()]).then(() => {
        formState.activeStatus = formState.active_status
        if (props.optType === "edit") {
          $api.modifyAccountMaintain(formState).then((res) => {
            if (res.code === 200) {
              //domain存在
              if (res.msg === '2') {
                message.error("Domain已存在");
                return
              }
              message.success("保存成功");
              handleAfterAdding();
            } else {
              message.error(res.msg);
            }
          });
        }
        if (props.optType === "add") {
          $api.addAccountMaintain(formState).then(async (res) => {
            if (res.code === 200) {
              //domain存在
              if (res.msg === '2') {
                message.error("Domain已存在");
                return
              } else if (res.msg === '1') {
                //account存在，强提示
                const message = "Account已存在，是否为该Account新增Domain?"
                handleStrongTip(message, 'addDomain')
                return
              } else {
                //account新建成功
                //同时新增domain
                await handleAddDomain(formState)
                const message = "Account新增成功,请确定是否创建对应TBD Code?"
                handleStrongTip(message, 'handleTbdCode', res.data)
              }
            } else {
              message.error(res.msg);
            }
          });
        }
      });
    };
    const handleAfterAdding = () => {
      emit("update:visible", false);
      emit("update");
      // 刷新account下拉列表
      emit("getAccountOptions");
      resetForm();
    }
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {type,data} = modalData.value
      if (type === 'addDomain') {
        handleAddDomain(formState)
      }
      //  account创建成功, 处理tbd code
      if (type === 'handleTbdCode') {
        if (data?.createTime) {
          data.createTime = moment(data.createTime).format("YYYY-MM-DD")
        }
        data.engagementCode = data.account + '_TBD'
        data.account = undefined;
        returnedData.value = [data]
        emit("handleAddTbd", returnedData.value)
      }
    }
    const handleStrongTip = (msg, type, data) => {
      content.value = msg
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = {type, data}
      modelValue.value = true
      /*Modal.confirm({
        title: () => '提示',
        content: msg,
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          //  account已存在,增加Domain
          if (type === 'addDomain') {
            handleAddDomain(formState)
          }
          //  account创建成功, 处理tbd code
          if (type === 'handleTbdCode') {
            if (data?.createTime) {
              data.createTime = moment(data.createTime).format("YYYY-MM-DD")
            }
            data.engagementCode = data.account + '_TBD'
            data.account = undefined;
            returnedData.value = [data]
            emit("handleAddTbd", returnedData.value)
          }
        },
      });*/
    };

    const handleAddDomain = async (data) => {
      await $api.addDomainMaintain(data).then(async (res) => {
        if (res.msg === '2') {
          message.error("Domain已存在");
          return
        }
        message.success("新增成功");
        await handleAfterAdding()
      })
    }

    const handleActive = () => {
      emit("handleActive", props.editRecord, "detail")
    };
    const handleDeactive = () => {
      emit("handleDeactive", props.editRecord, "detail")
    };

    const disabledDate = (current) => {
      if (formState.createDate) {
        if (props.optType === 'edit' && props.editRecord.active_status === '0') {
          return current && current > moment().endOf('day');
        }
        return moment(current) < moment(formState.createDate);
      } else {
        return false;
      }
    };

    const showRecord = () => {
      recordVisible.value = true;
    };

    const showEdit = () => {
      emit('showEditDrawer', props.editRecord.active_status)
    };

    const closeModal = () => {
      recordVisible.value = false;
    };
    const captiveList = ref([])
    const tierList = ref([])
    const getCaptiveAndTier = () => {
      $api.getCaptiveAndTier().then(res => {
        captiveList.value = res.data.CaptiveList
        tierList.value = res.data.TierList
      })
    }
    onMounted(() => {
      getCaptiveAndTier()
      getPlAndSubPlList()
    });

    watch(
      () => props.editRecord,
      (value) => {
        if (value.flag === 'detail') {
          updateFormState(value)
        }
        titleText.value.detail = value.account
      },
      { deep: true }
    );
    // watch(
    //   () => props.drawerSelectOptions.plParamList,
    //   (value) => {
    //     plOptionList.value = [...value];
    //   }
    // );
    // watch(
    //   () => props.drawerSelectOptions.subPlParamList,
    //   (value) => {
    //     subPlOptionList.value = [...value];
    //   }
    // );

    watch(
      () => formState.pl,
      (value) => {
        // if (!value) {
        //   // subPlOptionList.value = [...props.drawerSelectOptions.subPlParamList];
        //   return;
        // }
        for (let i in props.drawerSelectOptions.plObj) {
          if (value === i) {
            subPlOptionList.value = [...props.drawerSelectOptions.plObj[i]].map(
              (item) => {
                // return {
                //   label: item,
                //   value: item,
                // };
                return item
              }
            );
          }
        }
      }
    );
    // watch(
    //   () => formState.subPl,
    //   (value) => {
    //     if (!value) {
    //       plOptionList.value = [...props.drawerSelectOptions.plParamList];
    //       return;
    //     }
    //   }
    // );
    return {
      titleText,
      plOptionList,
      subPlOptionList,
      formRef1,
      formState,
      rules,
      handleCancel,
      handleSave,
      disabledDate,
      recordVisible,
      showRecord,
      closeModal,
      showEdit,
      updateFormState,
      handleActive,
      handleDeactive,
      getCaptiveAndTier,
      captiveList,
      tierList,
      returnedData,
      changePl,
      changeAccountCode,
      plsubpldisabled,
      getPlAndSubPlList,
      modelValue,
      content,
      showIconCom,
      modalData,
      handleOk,
    };
  },
});
</script>

<style lang="less" scoped>
.account-drawer {
  .header {
    display: flex;
    justify-content: flex-end;
  }

  .forms {
    display: flex;

    .form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      :deep(.ant-form-item) {
        width: 50%;
      }

      .item {
        width: 300px;
      }
    }
  }
}
</style>
