<template>
  <a-drawer :maskClosable="true" :closable="true" wrapClassName="merge-drawer" :width="'45vw'"
            :title="titleText" :visible="visible" @update:visible="(val) => $emit('update:visible', val)"
            :zIndex="900">
    <div class="header">
      <a-space>
        <a-button type="primary" ghost @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave()">保存</a-button>
      </a-space>
    </div>
    <div class="forms mt24">
      <a-form class="new-account" :model="dataSource" ref="formRef">
        <a-table
            :columns="columns"
            :data-source="dataSource.dataList"
            :pagination="false"
            :rowKey="(record) => { return record.id }"
        >
          <template #bodyCell="{ column, record, index }">

            <template v-if="column.dataIndex == 'oldAccountLabel'">
              <a-form-item :name="['dataList', index, 'oldAccountLabel']">
                <a-input :disabled="true" class="item" placeholder="请输入" v-model:value="record.oldAccountLabel"/>
              </a-form-item>
            </template>
            <template v-if="column.dataIndex == 'oldDomainLabel'">
              <a-form-item :name="['dataList', index, 'oldDomainLabel']">
                <a-input :disabled="true" class="item" placeholder="请输入" v-model:value="record.oldDomainLabel"/>
              </a-form-item>
            </template>
            <template v-if="column.dataIndex == 'newAccount'">
              <a-form-item :name="['dataList', index, 'newAccount']" label=" "
                           :rules="[{
                             required: true,
                             message: 'Account不能为空',
                             trigger: 'change',
                           },
                           {
                             validator: validatorNewAccount,
                             oldAccountId: record.accountId,
                             trigger: 'change'
                           }]">
                <a-select class="item"
                          allow-clear
                          placeholder="请选择"
                          show-search
                          optionFilterProp="label"
                          :options="allAccountOpts"
                          v-model:value="record.newAccount"
                          @change="handleChange(record)"/>
              </a-form-item>
            </template>
            <template v-if="column.dataIndex == 'newDomain'">
              <a-form-item :name="['dataList', index, 'newDomain']" label=" " :rules="{
                        required: true,
                        message: 'Domain不能为空',
                        trigger: 'change',
                    }">
                <a-select class="item" placeholder="请选择" show-search
                          allow-clear :options="record.domainList" v-model:value="record.newDomain"/>
              </a-form-item>
            </template>
          </template>
        </a-table>
      </a-form>
    </div>
  </a-drawer>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, watch} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
// import {useStore} from "vuex";

export default defineComponent({
  name: "MergeDomianDrawer",
  emits: ["update", "handleSaveMergedDomian"],
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    initialMergingData: {
      type: Array,
      default: () => {
        return []
      },
    },
    allAccountOpts: {
      type: Array,
      default: () => {
        return []
      },
    },
    allDomainOpts: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  setup(props, {emit}) {
    const titleText = ref("合并");
    const formRef = ref();
    const formState = reactive({
      dataSource: [{
        oldAccountLabel: "",// 页面展示值
        oldAccount: "",// 接口传参值，为id
        oldDomainLabel: "",
        oldDomain: "",
        newAccount: "",
        newDomain: "",
        id: "",
      }],
    });
    const columns = [
      {
        title: 'Account',
        dataIndex: 'oldAccountLabel',
        width: 150,
        slots: {customRender: "bodyCell"},
      },
      {
        title: 'Domain',
        dataIndex: 'oldDomainLabel',
        width: 150,
        slots: {customRender: "bodyCell"},
      },
      {
        title: '新Account',
        dataIndex: 'newAccount',
        width: 150,
        ellipsis: true,
        slots: {customRender: "bodyCell"},
      },
      {
        title: '新Domain',
        dataIndex: 'newDomain',
        width: 150,
        ellipsis: true,
        slots: {customRender: "bodyCell"},
      },
    ];
    const validatorNewAccount = async (rule, value) => {
      if (value === rule.oldAccountId) {
        return Promise.reject('请选择不同的Account');
      } else {
        return Promise.resolve();
      }
    };

    const updateFormState = (source) => {
      //重命名
      const {sub_pl: subPl, create_date: createDate, terminated_date: terminatedDate} = source
      Object.assign(source, {subPl, createDate, terminatedDate})
      Object.assign(formState, source)
    };

    const recordVisible = ref(false);

    const handleCancel = () => {
      emit("update:visible", false);
    };
    // const store = useStore();
    const handleSave = () => {
      Promise.all([formRef.value.validate()]).then(() => {
        emit("handleSaveMergedDomian", dataSource.value.dataList)
      });
    };
    const dataSource = ref({
      dataList: [{
        oldAccountLabel: "",// 页面展示值
        oldAccount: "",// 接口传参值，为id
        oldDomainLabel: "",
        oldDomain: "",
        newAccount: "",
        newDomain: "",
        id: "",
        domainOpt: [],
      }]
    })
    const { $api } = useGlobalPropertyHook();

    const handleChange = (data) => {
      if(!data.newAccount || data.newAccount === data.oldAccount) {
        data.domainList = [...data.domainData]
        return
      }
      const params = {
        accountId : data.newAccount
      }
      $api.getTheDomainOpt(params).then((res) => {
        const newDomainList = getDictionary(res.data)
        data.domainList.splice(0)
        data.domainList = [...newDomainList,...data.domainData]
      })
    }
    const getDictionary = (list) => {
      const domainOpt = list.map(data => {
        return {
          label: data.domain,
          value: data.id,
          key: data.id,
        }
      })
      return domainOpt
    }
    watch(
        () => props.visible,
        (value) => {
          if (value) {
            // 每次抽屉打开都清空表单校验信息
            formRef?.value?.resetFields();
            const mergingData = props.initialMergingData
            mergingData.forEach(item => {
              item.domainData = getDictionary(item.domainData)
              item.domainList = [...item.domainData]
            })
            dataSource.value.dataList.splice(0)
            Object.assign(dataSource.value.dataList, mergingData)
          }
        },
        {deep: true}
    );

    onMounted(async () => {
    });
    return {
      titleText,
      formRef,
      formState,
      handleCancel,
      handleSave,
      recordVisible,
      updateFormState,
      columns,
      dataSource,
      handleChange,
      validatorNewAccount,
    };
  },
});
</script>

<style lang="less" scoped>
.merge-drawer {
  .header {
    display: flex;
    justify-content: flex-end;
  }

  .new-account {
    :deep(.ant-form-item-label) > label::after {
      content: "" !important;
    }

    :deep(.ant-form-item-label) {
      margin-left: -18px;
    }
  }

  .forms {
    :deep(.ant-form-item-control) {
      overflow: hidden;
    }

    :deep(.ant-table) table {
      border-spacing: 0px;
      border-collapse: collapse;
    }

    :deep(.ant-table-thead) > tr > th {
      background: #fff;
    }
    :deep(.ant-table) .ant-table-tbody > tr > td {
      border: none;
    }
  }
}
</style>
