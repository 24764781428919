<template>
  <div class="deactivemodal" ref="deactiveModalRef">
    <a-drawer
        :visible="visible"
        @close="closeModal"
        :width="'40vw'"
        title="选择Terminated Date"
        :getContainer="() => $refs.deactiveModalRef"
    >
      <a-form :model="dataSource" ref="formData">
        <a-table
            :columns="columns"
            :data-source="dataSource"
            :pagination="false"
            row-key="id"
        >
          <template #newRange="{index,record}">
            <a-form-item :name="[index, 'terminatedDate']" :rules="{
                               required: true,
                               message: 'Terminated Date不能为空',
                               trigger: 'change',
                             }">
            <a-date-picker
                class="item"
                allow-clear
                type="date"
                placeholder="选择日期"
                valueFormat="YYYY-MM-DD"
                :disabledDate="current => disabledDate(current, record,index)"
                v-model:value="record.terminatedDate"
            />
            </a-form-item>
          </template>
        </a-table>
      </a-form>
      <div class="btn-row bottom-btn">
        <div class="left"></div>
        <div class="right">
          <a-space>
            <a-button type="primary" ghost @click="closeModal">取消</a-button>
            <a-button type="primary" @click="handelSave"
            >保存
            </a-button>
          </a-space>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {defineComponent, ref, watch} from "vue";
import moment from "moment";
import { computed } from "@vue/runtime-core";

export default defineComponent({
  name: "BatchEditAssignmentTimeDrawer",
  props: {
    visible: Boolean,
    recordDate: {
      type: Array,
      default: () => {
        return []
      }
    },
    deactiveData: {
      type: Object,
      default: () => {
        return {
          deactiveDataValue: [],
          deactiveDataKey: []
        }
      }
    },
    getProjectCloseDate: {
      type: Object,
      default: () => {
        return {
          getProjectCloseDateValue:[],
          getProjectCloseDateKey: [],
        }
      }
    }
  },
  emits: ["close-modal", "refresh-table"],
  components: {},
  setup(props, { emit }) {
    const dataSource = ref([]);

    const columns = computed(() => {
      return [
        {
          title: "Account",
          dataIndex: "account",
          key: "account",
          width: 100,
          ellipsis: true
        },
        {
          title: "Domain",
          dataIndex: "domain",
          key: "domain",
          width: 100,
          ellipsis: true
        },
        {
          title: "Terminated Date",
          dataIndex: "terminatedDate",
          key: "terminatedDate",
          width: 200,
          slots: { customRender: "newRange" }
        },
      ];
    });

    watch(
        () => props.visible,
        (val) => {
          if (val) {
            dataSource.value = props.recordDate.map((item) => {
              return {
                id: item.id || '',
                account: item.account || '',
                domain: item.domain || '',
                terminatedDate: item.terminatedDate || '',
              }
            });
          } else {
            formData?.value?.resetFields();
            dataSource.value.terminatedDate = null
          }
        }
    );

    const closeModal = () => {
      emit("close-modal");
    };
    const formData = ref()
    const handelSave = () => {
      Promise.all([formData.value.validate()]).then(() => {
        emit("save-modal", dataSource.value);
      });
    }

    const disabledDate = (current,record) => {
      let closeDateIndex = props.getProjectCloseDate.getProjectCloseDateKey.indexOf(record.domain)
      let deactiveIndex = props.deactiveData.deactiveDataKey.indexOf(record.domain)
      if (props.getProjectCloseDate.getProjectCloseDateValue[closeDateIndex] === '') {
        if (props.deactiveData.deactiveDataValue[deactiveIndex] === 0) {
          return current < moment().subtract(1, 'day')
        } else if (props.deactiveData.deactiveDataValue[deactiveIndex] > 0) {
          return current < moment().add(29, 'day')
        }
      } else {
        if (props.deactiveData.deactiveDataValue[deactiveIndex] === 0) {
          return current < moment().subtract(1, 'day') || moment(props.getProjectCloseDate.getProjectCloseDateValue[closeDateIndex]) >= current;
        } else if (props.deactiveData.deactiveDataValue[deactiveIndex] > 0) {
          return current < moment().add(29, 'day') || moment(props.getProjectCloseDate.getProjectCloseDateValue[closeDateIndex]) >= current;
        }
      }
    };

    return {
      columns,
      closeModal,
      handelSave,
      dataSource,
      disabledDate,
      formData,
    };
  }
});
</script>

<style lang="less" scoped>
.btn-row {
  display: flex;
  .left {
    width: 50%;
  }

  .right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}

.ant-form-item {
  margin-bottom: 0px !important;
}
.overlap-hidden {
  visibility: hidden;
}
.deactivemodal {
  .ant-input-textarea-show-count {
    position: relative;

    &::after {
      font-size: 12px;
      position: absolute;
      right: 12px;
      bottom: 5px;
      line-height: 1;
      margin-bottom: unset !important;
    }
  }
}
</style>
