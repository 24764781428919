<template>
  <a-row>
    <a-col :span="12">
      <a-form-item label="Engagement Code" :name="['dataList', index, 'engagementCode']" :rules="[{
      required: true,
      message: 'Engagement Code不能为空',
      trigger: 'change' }]">
        <a-input :disabled="true" class="item" placeholder="请输入" allow-clear v-model:value="itemData.engagementCode"/>
      </a-form-item>
    </a-col>
    <a-col :span="12">
      <a-form-item label="Leading CSM Number" :name="['dataList', index, 'engagementManagerNumber']" :rules="EMNumber">
        <a-input class="item" placeholder="请输入" allow-clear v-model:value="itemData.engagementManagerNumber"
                 :maxlength="13" autocomplete="off"/>
      </a-form-item>
    </a-col>
    <a-col :span="12">
      <a-form-item label="Leading CSM Name" :name="['dataList', index, 'engagementManagerName']" :rules="EMName">
        <a-input class="item" placeholder="请输入" allow-clear v-model:value="itemData.engagementManagerName"
                 :maxlength="25"  autocomplete="off"/>
      </a-form-item>
    </a-col>
    <a-col :span="12">
      <a-form-item label="Project Start Date" :name="['dataList', index, 'createTime']" :rules="[{
      required: true,
      message: 'Project Start Date不能为空',
      trigger: 'change' }]">
        <a-input :disabled="true" class="item" placeholder="请输入" v-model:value="itemData.createTime"/>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import {ref, watch, computed} from "vue"

export default {
  name: "AddTbdCodeForm",
  props: {
    // 该数据内的字段要对应表单内字段
    formData: {
      type: Object,
      default: () => {
        return {
          engagementCode: '',
          createTime: '',
          engagementManagerNumber: '',
          engagementManagerName: '',
        }
      }
    },
    index: Number,
  },
  setup(props, {emit}) {
    const formState = ref({
      engagementCode: '',
      createTime: '',
      engagementManagerNumber: '',
      engagementManagerName: '',
    })
    const EMNumber = [
      {
        required: true,
        message: 'Leading CSM Number不能为空',
        trigger: 'change',
      },
      {
        pattern: /^[0-9_]+$/,
        message: '只能输入数字和下划线',
        trigger: 'change',
      },
    ]
    const EMName = [
      {
        required: true,
        message: 'Leading CSM Name不能为空',
        trigger: 'change'
      },
      {
        pattern: /^[a-zA-Z, ]+$/,
        message: "只能输入字母（大小写均可），英文逗号，空格",
        trigger: 'change'
      },
    ]

    const formRef = ref()

    const handleValidateForm = async () => {
      let flag = false
      await Promise.all([formRef.value.validate()]).then(() => {
        flag = true
      })
      return flag
    }

    const itemData = computed({
      get: () => props.formData,
      set: (val) => {
        emit("update:formData", val)
      }
    })
    watch(
        () => props.formData,
        (value) => {
          Object.assign(formState.value, value)
        },
        {
          deep: true,
          immediate: true,
        }
    );

    return {
      formState,
      labelCol: {span: 22},
      wrapperCol: {span: 22},
      formRef,
      handleValidateForm,
      itemData,
      EMNumber,
      EMName,
    }
  }

}
</script>

<style scoped>

</style>
