<template>
  <a-modal
    :getContainer="getRefsAff"
    :visible="visible"
    @cancel="closeModal"
    width="62.5vw"
    :footer="null"
    centered
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "查看变更记录" }}
      </strong>
    </template>
    <a-table
      :data-source="tableData"
      :columns="columns"
      rowKey="id"
      :scroll="{
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
    >
    </a-table>
  </a-modal>
</template>
<script>
import { defineComponent, ref, watch, reactive } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";

export default defineComponent({
  name: "ChangeRecordModal",
  props: {
    visible: Boolean,
    title: String,
    id: String
  },
  emits: ["close-modal"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData();
      },
      onShowSizeChange: (current, size) => {
        pagination.pageSize = size;
        pagination.current = current;
        getTableData();
      }
    });

    const columns = ref([
      {
        title: "操作人",
        dataIndex: "createOperator",
        ellipsis: true,
        width: 15,
      },
      {
        title: "操作时间",
        dataIndex: "createTime",
        ellipsis: true,
        width: 20,
      },
      {
        title: "变更内容",
        dataIndex: "changeContent",
        ellipsis: true,
        width: 80,
      }
    ]);
    const tableData = ref([]);
    const getTableData = () => {
      const params = {
        id: props.id,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize
      };
      // @todo 待修改接口
      $api.getAccountHistory(params).then((res) => {
        pagination.current = res.data?.pageIndex + 1;
        pagination.pageSize = res.data?.pageSize;
        pagination.total = res.data?.total;
        tableData.value = res.data?.resource || [];
      });
    };

    watch(
      () => props.visible,
      (val) => {
        if (val) {
          tableData.value = [];
          pagination.current = 1;
          getTableData();
        }
      }
    );

    const closeModal = () => {
      ctx.emit("close-modal");
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      columns,
      tableData,
      getRefsAff,
      closeModal,
      pagination
    };
  }
});
</script>
<style lang="less" scoped>
::v-deep .ant-table-thead > tr > th {
  background-color: #fafafa !important;
}

::v-deep .ant-table-body {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 13px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

:deep(.ant-table-thead > tr > th) {
  background-color: #e9f4ff;
}

.ant-table-tbody {
  > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-row-hover,
  .ant-table-row-hover > td {
    background: #e9f4ff;
  }
}

.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

.ant-table.ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-tbody
> tr
> td {
  border-bottom: #d0e8ff 1px solid;
}

.ant-table-striped ::v-deep(.table-striped) td {
  background-color: #fefeff;
}

.editable-row-operations a {
  margin-right: 8px;
}

.modal-title {
  font-size: 22px;
  margin-top: 4px;
  font-weight: 700;
}

.ant-calendar-picker {
  width: 100%;
}

.footerBtn {
  text-align: center;
  margin: 5px;
}
</style>
