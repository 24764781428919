<template>
  <div class="Three-MappingRelation main-padding">
    <a-table
        :columns="columns"
        :data-source="tableList"
        rowKey="id"
        :scroll="{

          scrollToFirstRowOnChange: true,
        }"
        :pagination="false"
    >
    </a-table>
  </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
  name: "ThreeMappingRelation",
  props: {
    mergeDomainData: {
      type: Array,
      default: () => [],
    },
  },
  setup(props,){
    const columns = [
      {
        title: "原Account",
        dataIndex: "account",
        key: "account",
        width: 70,
        ellipsis: true
      },
      {
        title: "原Domain",
        dataIndex: "oldDomain",
        key: "oldDomain",
        width: 70,
        ellipsis: true
      },
      {
        title: "新Account",
        dataIndex: "newAccount",
        key: "newAccount",
        width: 70,
        ellipsis: true
      },
      {
        title: "新Domain",
        dataIndex: "newDomain",
        key: "newDomain",
        width: 70,
        ellipsis: true
      },
    ];
    const tableList = ref([]);

    watch(
        () => props.mergeDomainData,
        (val) => {
          if(val) {
            tableList.value = props.mergeDomainData
            console.log(tableList.value)
          }
        },
        {immediate:true}
    )

    return {
      columns,
      tableList,

    }
  }
}
</script>

<style scoped>
.Three-MappingRelation{
  margin: 24px 0 52px 0;
}
.main-padding{
  padding: 0 24px;
}
</style>
