<template>
  <a-modal
      class="tbdModal"
      :getContainer="getRefsAff"
      :visible="visible"
      @cancel="closeModal"
      width="40.6vw"
      :zIndex="1000"
      :title="title"
      centered
  >
    <template #footer>
      <a-space>
        <a-button @click="closeModal" type="primary" ghost>取消</a-button>
        <a-button @click="handelSave" type="primary">保存</a-button>
      </a-space>
    </template>
    <a-form layout="vertical" class="form" ref="formRef" :model="formState" :label-col="labelCol"
            :wrapper-col="wrapperCol">
      <template v-for="(item, index) in formState.dataList" :key="index">
        <a-form-item :label="item.account" class="titleLabel" v-if="item.account"></a-form-item>
        <AddTbdCodeFormItem :formData="item" :index="index"></AddTbdCodeFormItem>
      </template>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, watch } from "vue"
import AddTbdCodeFormItem from "@/views/home/BaseDataManagement/Account/components/AddTbdCodeFormItem"

export default {
  name: "AddTbdCodeModal",
  emits: ["close-modal", "save-modal"],
  components: {
    AddTbdCodeFormItem,
  },
  props: {
    visible: Boolean,
    title: String,
    initDataList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  setup(props,{ emit }) {
    // @todo 待确定字段
    const formState = ref({
      dataList: [{
        id: '',
        account: '',
        engagementCode: '',
        createTime: '',
        engagementManagerNumber: '',
        engagementManagerName: '',
      }],
    })
    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const closeModal = () => {
      emit("close-modal");
    };

    const formRef = ref()

    const handelSave = async () => {
      Promise.all([formRef.value.validate()]).then(() => {
        emit("save-modal",formState.value.dataList);
      })
    };

    watch(
        () => props.visible,
        (value) => {
          if(!value) {
            // 每次抽屉关闭都清空表单校验信息
            formRef?.value?.resetFields();
          }
        }
    )
    watch(
        () => props.initDataList,
        (value) => {
          formState.value.dataList = value.length > 0 ? value : formState.value.dataList
          console.log('formState.value:',formState.value)
        }
    )

    onMounted(() => {
    })

    return {
      formState,
      getRefsAff,
      closeModal,
      handelSave,
      formRef,
      labelCol: { span: 22 },
      wrapperCol: { span: 22 },
    }
  }
}
</script>

<style lang="less">
.tbdModal {

  .titleLabel {
    label {
      font-weight: 400;
      font-size: 22px !important;
    }
  }

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
  }
}
</style>
