<template>
  <div class="rm-staff-container">
    <section class="app-container" ref="accountRef">
      <FilterLayout
        ref="filterLayoutRef"
        @doReset="resetForm"
        @doSearch="doSearch"
        @doCollapseEffect="updateTableHeight"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=formLength">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        allow-clear
                        v-model:value="formState.activeStatus"
                        placeholder="请选择"
                    >
                      <a-select-option key="Y" value="1">Y</a-select-option>
                      <a-select-option key="N" value="0">N</a-select-option>
                    </a-select>
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="orgAccountPLChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type==='6'">
                    <a-range-picker
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="['开始时间', '结束时间']"
                    />
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>formLength">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          allow-clear
                          v-model:value="formState.activeStatus"
                          placeholder="请选择"
                      >
                        <a-select-option key="Y" value="1">Y</a-select-option>
                        <a-select-option key="N" value="0">N</a-select-option>
                      </a-select>
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="orgAccountPLChange(item.inputDataType)"
                      >
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type==='6'">
                      <a-range-picker
                          v-model:value="formState[item.inputDataType]"
                          :placeholder="['开始时间', '结束时间']"
                      />
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <a-dropdown v-if="isShowMoreBtn" :getPopupContainer="()=>$refs.accountRef" :trigger="['click']" :placement="'bottomRight'" overlayClassName="dropdown">
              <a-button type="primary" ghost @click.prevent>More</a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                      key="0"
                      :disabled="selectedState.selectedId.length === 0 || !selectedState.handleActiveStatus.every((item) => item === '1')"
                      @click="handleDeactive"
                      v-auth="['system:accountMaintain:deactive']">
                    <StopOutlined class="icon-font red" />停用
                  </a-menu-item>
                  <a-menu-item
                      key="1"
                      :disabled=" selectedState.selectedId.length === 0 || !selectedState.handleActiveStatus.every((item) => item === '0')"
                      @click="handleActive"
                      v-auth="['system:accountMaintain:active']">
                    <CheckCircleOutlined class="icon-font" />启用
                  </a-menu-item>
                  <a-menu-item key="2" @click="openMergingClick" v-auth="['system:accountMaintain:merge']">
                    <MergeCellsOutlined class="icon-font" />合并
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <Tooltip title="导出">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="exportAccountData"
                  v-auth="['system:accountMaintain:export']"
              >
                <ExportOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="下载模板">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="downloadTemplate"
                  v-auth="['system:accountMaintain:downloadTemplate']"
              >
                <DownloadOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="导入">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="importAccountData"
                  v-auth="['system:accountMaintain:import']"
              >
                <ImportOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="新增">
              <a-button
                  class="ml12"
                  type="primary"
                  @click="addAccount"
                  v-auth="['system:accountMaintain:add']"
              >
                <PlusOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
      <div class="account-list">
        <AccountList
          :drawerSelectOptions="drawerSelectOptions"
          :searchParams="currentState"
          ref="accountListRef"
          @getAccountOptions="getAccountOptions"
          @handleSelectedDate="handleSelectedDate"
          @handleSelectedListDate="handleSelectedListDate"
          @getCaptiveAndTierList="getCaptiveAndTierList"
          @get-total="getTotal"
        >
        </AccountList>
        <AccountDrawer
            v-model:visible="drawerVisible"
            :drawerSelectOptions="drawerSelectOptions"
            :optType="optType"
            @update="doSearch"
            @getAccountOptions="getAccountOptions"
            @handleAddTbd="handleAddtbdcode"
        />
        <div
            class="message"
            v-auth="['system:accountMaintain:import']"
            v-if="dataBoxShow"
        >
          Account主数据：
          <span>新增Account数:</span>{{ accountStatusData.insertCount }},
          <span>新增Domain数:</span>{{ accountStatusData.insertDomain }},
          <span>更新Domain数:</span>{{ accountStatusData.updateDomain }},
          <span>任务状态:</span>{{ getStatus(accountStatusData.status) }},
          <span>操作人:</span>{{ accountStatusData.operator }},
          <span>操作时间:</span>{{ accountStatusData.operationTime }}
        </div>
        <div
            class="message1"
            v-auth="['system:accountMaintain:import']"
            v-if="noDataShow"
        >
          Account主数据：
          <span>新增Account数:</span>{{ accountStatusData.insertCount }},
          <span>新增Domain数:</span>{{ accountStatusData.insertDomain }},
          <span>更新Domain数:</span>{{ accountStatusData.updateDomain }},
          <span>任务状态:</span>{{ getStatus(accountStatusData.status) }},
          <span>操作人:</span>{{ accountStatusData.operator }},
          <span>操作时间:</span>{{ accountStatusData.operationTime }}
        </div>
      </div>

      <!-- 合并弹窗 -->
      <MergeDomianDrawer
        v-model:visible="mergeDomianVisible"
        :initialMergingData="initialMergingData"
        :allAccountOpts="allAccountOpts"
        :allDomainOpts="allDomainOpts"
        @handleSaveMergedDomian="handleSaveMergedDomian"
      ></MergeDomianDrawer>
      <!-- 新增tbd的弹窗  -->
      <AddTbdCodeModal
        v-model:visible="tbdCodeModalVisible"
        title="TBD Code 新增"
        :initDataList="tbdInitData"
        @close-modal="handleCloseModal('tbdCode')"
        @save-modal="saveTbdCode"
      ></AddTbdCodeModal>

      <import-modal
        v-model:visible="importModalVisible"
        apiFuncName="getAccountImport"
        @closeModal="handleCloseModal('import')"
        @resetTable="doUpdateImport"
        @insertModal="doInsertImport"
      />
      <select-add-modal
        v-model:visible="tbdCodeVisible"
        :tbd-code-list="tbdCodeList"
        @close-modal="handleCloseModal('selectTbdCode')"
        @handleAddtbdcode="handleAddtbdcode"
      />
      <!-- 停用触发的弹窗  -->
      <DeactiveModal
        :recordDate="selectedState.selectActive"
        :deactiveData="deactiveData"
        :getProjectCloseDate="getProjectCloseDate"
        v-model:visible="deactiveModalVisible"
        @close-modal="handleCloseModal('deactiveModal')"
        @save-modal="saveDeactiveModal"
      ></DeactiveModal>
      <a-modal
        v-model:visible="modalVisible"
        title="Terminated Date录入"
        ok-text="确定"
        cancel-text="取消"
        @ok="handleOk"
        @cancel="handleCancel"
      >
        <span v-html="deactiveError"></span>
      </a-modal>
      <NewMergingModal
        v-model:visible="newMergeDomianVisible"
        @handleSaveNewMergedDomian="handleSaveNewMergedDomian"
      >
      </NewMergingModal>
    </section>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="sortFlag" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
    <ModalBox v-model="modelValue" :ok="handleOk1" :content="content" :showIconCom="showIconCom" />
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  computed,
} from "vue";
import AccountList from "./components/AccountList";
import AccountDrawer from "./components/AccountDrawer.vue";
import MergeDomianDrawer from "@/views/home/BaseDataManagement/Account/components/MergeDomianDrawer";
import {dictionaryOpt, downloadFile} from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";
import {cloneDeep} from "lodash";
import ImportModal from "@/components/ImportModal";
import { getStatusInAccount } from "@/utils/formatStatus";
import AddTbdCodeModal from "@/views/home/BaseDataManagement/Account/components/AddTbdCodeModal";
import {
  DownloadOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  StopOutlined,
  CheckCircleOutlined,
  MergeCellsOutlined
} from "@ant-design/icons-vue";
import SelectAddModal from "@/views/home/BaseDataManagement/Account/components/SelectAddModal";
import DeactiveModal from "@/views/home/BaseDataManagement/Account/components/DeactiveModal";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NewMergingModal from "@/views/home/BaseDataManagement/Account/components/NewMergingModal";
import ModalBox from '@/components/ModalBox'

import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
export default defineComponent({
  name: "AccountMainData",
  components: {
    NewMergingModal,
    AccountDrawer,
    AccountList,
    ImportModal,
    MergeDomianDrawer,
    AddTbdCodeModal,
    SelectAddModal,
    DeactiveModal,
    DownloadOutlined,
    PlusOutlined,
    ModalBox,
    ExportOutlined,
    ImportOutlined,
    MoreFormList,
    FormModalBox,
    Tooltip,
    StopOutlined,
    CheckCircleOutlined,
    MergeCellsOutlined
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const route = useRoute();

    const accountListRef = ref();

    const dataBoxShow = ref(true);
    const noDataShow = ref(false);
    const isShowMoreBtn = ref(false)
    let switchRoleName = localStorage.getItem('switchRoleName')
    let formLength = switchRoleName === 'Admin'? 3:4
    isShowMoreBtn.value = switchRoleName === 'Admin' ? true: false
    const sortFlag = ref(false)
    sortFlag.value = switchRoleName === 'RM'|| switchRoleName === 'RM Leader' ? true: false
    let initData = {
      account: [],
      domain: [],
      pl: [],
      subPl: [],
      activeStatus: null,
      createDateBegin: null,
      createDateEnd: null,
      terminatedDateBegin: null,
      terminatedDateEnd: null,
      createDateBeginList: [],
      terminatedDateBeginList: [],
    }
    const formState = reactive({...initData});

    // 点击查询重置才更新参数
    const currentState = ref({});
    const updateParams = () => {
      currentState.value = cloneDeep(formState);
    };

    const plObj = {};

    const accountOps = ref([]);
    const domainOps = ref([]);
    const plOps = ref([]);
    const subPlOps = ref([]);

    const drawerVisible = ref(false);
    const drawerType = ref("add");

    const doSearch = () => {
      updateParams();
      accountListRef.value.resetSelectState();
      setTimeout(() => {
        accountListRef.value.searchClick();
      });
    };

    const resetForm = () => {
      Object.assign(formState,initData)
      accountListRef.value.resetSelectState();
      accountListRef.value.resetAll();
      updateParams();
      doSearch();
      getAccountPLItems();
    };

    const selectedState = reactive({
      selectedId: [],
      selectActive: [],
      handleActiveStatus: [],
    });
    const handleSelectedDate = (value) => {
      selectedState.selectedId = value;
    };
    const handleSelectedListDate = (value) => {
      selectedState.selectActive = value;
      selectedState.handleActiveStatus = JSON.parse(
        JSON.stringify(selectedState.selectActive)
      ).map((item) => item.active_status);
    };
    const isShowBtn = ref(false);
    const handleShowBtn = () => {
      isShowBtn.value = !isShowBtn.value;
    };
    const updateTableHeight = () => {
      accountListRef.value.getSize();
    };

    const drawerSelectOptions = reactive({
      plParamList: [],
      subPlParamList: [],
      captiveParamList: [],
      tierParamList: [],
      plObj,
    });

    const getPlAndSubPlRelation = () => {
      $api.getPAndLSub().then((res) => {
        if (res.code === 200) {
          res.data?.forEach((map) => {
            if (plObj[map.PLValue]) {
              plObj[map.PLValue].push(map.industryValue);
            } else {
              plObj[map.PLValue] = [map.industryValue];
            }
          });
        } else {
          message.error(res.msg);
        }
      });
    };

    const accountStatusData = ref({});

    const getAccountStatus = () => {
      $api.getAccountStatusData().then((res) => {
        accountStatusData.value = res.data;
      });
    };

    const getStatus = getStatusInAccount;

    // 获取新增编辑抽屉内的captive和tier下拉框内容
    const getCaptiveAndTierList = () => {
      // 获取cative下拉框的接口
      $api.getCaptiveSelData().then((res) => {
        drawerSelectOptions.captiveParamList = dictionaryOpt(res.data);
      });
      // 获取tier下拉框的接口
      $api.getTierSelData().then((res) => {
        drawerSelectOptions.tierParamList = dictionaryOpt(res.data);
      });
    };

    // 合并-打开合并弹窗
    const mergeDomianVisible = ref(false);
    const initialMergingData = ref([]);
    const allAccountOpts = ref([]);
    const allDomainOpts = ref([]);
    const getAccountOpts = () => {
      $api.getAllAccountOpt().then((res) => {
        allAccountOpts.value = res.data.map((item) => {
          return {
            ...item,
            label: item.account,
            value: item.id,
            key: item.id,
          };
        });
        console.log(res);
      });
    };
    const openMergingModal = () => {
      const data = {
        id: selectedState.selectedId,
      };
      $api.getMergingData(data).then((res) => {
        getAccountOpts();
        initialMergingData.value = res.data;
        initialMergingData.value.map((item) => {
          item.oldAccountLabel = item.account;
          item.oldAccount = item.accountId;
          item.oldDomainLabel = item.domain;
          item.oldDomain = item.id;
          item.newDomain = item.id;
        });
        mergeDomianVisible.value = true;
      });
    };
    const store = useStore();
    // 提交合并
    const handleSaveMergedDomian = (data) => {
      $api.mergeDomainData(data).then(() => {
        doSearch();
        message.success("合并成功！");
        handleCloseModal("mergeModal");
        store.dispatch("getCountNum");
      });
    };

    const exportAccountData = () => {
      const params = accountListRef.value.getSearchParams();
      $api.getAccountExport(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    const downloadTemplate = () => {
      $api.downloadTemplate().then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    const importModalVisible = ref(false);

    const importAccountData = () => {
      importModalVisible.value = true;
    };

    const handleCloseModal = (type) => {
      if (type === "import") {
        importModalVisible.value = false;
      }
      if (type === "tbdCode") {
        tbdCodeModalVisible.value = false;
      }
      if (type === "mergeModal") {
        mergeDomianVisible.value = false;
        getAccountPLItems();
      }
      if (type === "selectTbdCode") {
        tbdCodeVisible.value = false;
        getAccountPLItems();
      }
      if (type === "deactiveModal") {
        deactiveModalVisible.value = false;
      }
    };

    const deactiveData = ref({
      deactiveDataValue: [],
      deactiveDataKey: [],
    });
    const getProjectCloseDate = ref({
      getProjectCloseDateValue: [],
      getProjectCloseDateKey: [],
    });
    const deactiveModalVisible = ref(false);
    const modalVisible = ref(false);
    const deactiveError = ref({});

    const handleOk = () => {
      modalVisible.value = false;
      message.success("成功");
      doSearch();
    };
    const handleCancel = () => {
      modalVisible.value = false;
      doSearch();
    };
    const saveDeactiveModal = (data) => {
      const params = data.map((item) => {
        return {
          domain: item.domain,
          terminatedDate: item.terminatedDate,
        };
      });
      $api.deactiveAccountMaintain(params).then((res) => {
        if (res.data.length === 0) {
          doSearch();
          message.success("成功");
        } else {
          deactiveError.value = res.data.join(`<br/>`);
          modalVisible.value = true;
        }
        deactiveModalVisible.value = false;
      });
      doSearch();
    };
    //停用
    const handleDeactive = () => {
      const params = JSON.parse(JSON.stringify(selectedState.selectActive)).map(
        (item) => {
          return {
            domain: item.domain,
          };
        }
      );
      $api.getIsAssignment(params).then((res) => {
        deactiveData.value.deactiveDataValue = Object.values(
          JSON.parse(JSON.stringify(res.data))
        );
        deactiveData.value.deactiveDataKey = Object.keys(
          JSON.parse(JSON.stringify(res.data))
        );
      });
      $api.getProjectCloseDate(params).then((res) => {
        getProjectCloseDate.value.getProjectCloseDateValue = Object.values(
          JSON.parse(JSON.stringify(res.data))
        );
        getProjectCloseDate.value.getProjectCloseDateKey = Object.keys(
          JSON.parse(JSON.stringify(res.data))
        );
      });
      deactiveModalVisible.value = true;
    };
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const handleOk1 = () => {
      const params = JSON.parse(
          JSON.stringify(selectedState.selectActive)
      ).map((item) => item.domain);
      $api.activeAccountMaintain(params).then((res) => {
        if (res.code === 200) {
          message.success("成功");
          doSearch();
        } else {
          message.error("失败");
        }
      });
    }
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    //启用
    const handleActive = () => {
      content.value = '确定启用该人员信息吗？'
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
      /*Modal.confirm({
        title: () => "提示",
        content: "确定启用该人员信息吗？",
        width: "20%",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          const params = JSON.parse(
            JSON.stringify(selectedState.selectActive)
          ).map((item) => item.domain);
          $api.activeAccountMaintain(params).then((res) => {
            if (res.code === 200) {
              message.success("Success");
              doSearch();
            } else {
              message.error("Fail");
            }
          });
        },
      });*/
    };

    const tbdCodeVisible = ref(false);
    const tbdCodeList = ref([]);

    const handleAddtbdcode = (value) => {
      tbdCodeVisible.value = false;
      tbdInitData.value = value;
      tbdCodeModalVisible.value = true;
    };
    //导入更新
    const doUpdateImport = () => {
      getAccountStatus();
      doSearch();
      getAccountOptions();
    };
    //导入新增
    const doInsertImport = () => {
      $api.getAccountStatusData().then((res) => {
        accountStatusData.value = res.data;
        $api
          .getStatusAccountData({
            importId: accountStatusData.value.id,
          })
          .then((res) => {
            tbdCodeList.value = res.data;
            tbdCodeVisible.value = true;
          });
      });
      doSearch();
    };

    const optType = ref("");

    const addAccount = () => {
      optType.value = "add";
      // @todo
      // getCaptiveAndTierList()
      drawerVisible.value = true;
    };

    // 新增TBD
    const tbdCodeModalVisible = ref(false);
    // tbd code新增弹窗的初始数据
    const tbdInitData = ref([]);

    // const handleOpenTbdModal = () => {
    //   tbdCodeModalVisible.value = true
    // }

    const saveTbdCode = (params) => {
      $api.addTbdCode(params).then(() => {
        handleCloseModal("tbdCode");
        message.success("操作成功！");
        getAccountPLItems();
      });
    };

    const paramListObj = reactive({
      accountOps: [],
      domainOps: [],
      plOps: [],
      subPlOps: [],
    });
    //获得Account、Domain、P&L、Sub_P&L 下拉列表
    const getAccountPLItems = async () => {
      await $api.getAccountPLItems().then((res) => {
        const { account, domain, pl, subPl } = res.data;
        paramListObj.accountOps = dictionaryOpt(account);
        paramListObj.domainOps = dictionaryOpt(domain);
        paramListObj.plOps = dictionaryOpt(pl);
        paramListObj.subPlOps = dictionaryOpt(subPl);
        drawerSelectOptions.plParamList = dictionaryOpt(pl);
        drawerSelectOptions.subPlParamList = dictionaryOpt(subPl);
      });
    };
    //Account、Domain、P&L、Sub_P&L change对应关系
    const orgAllList = ref([]);
    const getAccountPLRelations = async () => {
      await $api.getAccountPLRelations().then((res) => {
        orgAllList.value = res.data;
      });
    };
    //Account、Domain、P&L、Sub_P&L change事件
    const orgAccountPLChange = (type, isInitUnderData = true) => {
      const formOrgData = {
        account: formState.account,
        domain: formState.domain,
        pl: formState.pl,
        subPl: formState.subPl,
      };
      const { orgsValue, orgOpsList } = getValueAndOps(
        orgAllList.value,
        formOrgData,
        paramListObj,
        type,
        isInitUnderData
      );
      Object.assign(formState, JSON.parse(JSON.stringify(orgsValue)));
      Object.assign(paramListObj, orgOpsList);
    };
    const getValueAndOps = (
      orgAllList = [],
      formOrgData,
      orgOpsObj,
      type,
      isInitUnderData
    ) => {
      const orgTypes = ["account", "domain", "pl", "subPl"];
      const data = isInitUnderData
        ? initUnderData(orgTypes, formOrgData, type)
        : formOrgData;
      // 查询
      const orgsData = getOrgOptData(
        orgAllList,
        formOrgData.account,
        formOrgData.domain,
        formOrgData.pl,
        formOrgData.subPl
      );
      //Sub_P&L先选值，其他3项不过滤
      const accountOpsData = getOrgOptData(
        orgAllList,
        [],
        formOrgData.domain,
        formOrgData.pl,
        []
      );
      const domainOpsData = getOrgOptData(
        orgAllList,
        formOrgData.account,
        [],
        formOrgData.pl,
        []
      );
      const plOpsData = getOrgOptData(
        orgAllList,
        formOrgData.account,
        formOrgData.domain,
        [],
        []
      );
      // 更新4个下拉框对应option数据
      orgTypes.forEach((item) => {
        if (type !== item || formOrgData[item]?.length === 0) {
          if (item === "account") {
            orgOpsObj[item + "Ops"] = dictionaryOpt(accountOpsData[item + "Ops"]);
          } else if (item === "domain") {
            orgOpsObj[item + "Ops"] = dictionaryOpt(domainOpsData[item + "Ops"]);
          } else if (item === "pl") {
            orgOpsObj[item + "Ops"] = dictionaryOpt(plOpsData[item + "Ops"]);
          } else {
            orgOpsObj[item + "Ops"] = dictionaryOpt(orgsData[item + "Ops"]);
          }
        }
      });
      return {
        orgsValue: data,
        orgOpsList: orgOpsObj,
      };
    };
    const initUnderData = (orgTypes, formOrgData, type) => {
      for (let i = orgTypes.indexOf(type); i < orgTypes.length; i++) {
        if (i + 1 !== orgTypes.length) {
          if (formOrgData[orgTypes[i + 1]]?.length > 0) {
            formOrgData[orgTypes[i + 1]] = [];
          }
        }
      }
      return formOrgData;
    };
    const getOrgOptData = (
      orgAllList = [],
      account = [],
      domain = [],
      pl = [],
      subPl = []
    ) => {
      const orgTypes = ["account", "domain", "pl", "subPl"];
      const selectedOrgObj = {
        account: {},
        domain: {},
        pl: {},
        subPl: {},
      };
      account.length > 0 &&
        account.forEach((item) => {
          selectedOrgObj.account[item] = true;
        });
      domain.length > 0 &&
        domain.forEach((item) => {
          selectedOrgObj.domain[item] = true;
        });
      pl.length > 0 &&
        pl.forEach((item) => {
          selectedOrgObj.pl[item] = true;
        });
      subPl.length > 0 &&
        subPl.forEach((item) => {
          selectedOrgObj.subPl[item] = true;
        });
      const orgs = orgAllList.filter((item) => {
        if (
          (account.length > 0 ? selectedOrgObj.account[item.account] : true) &&
          (domain.length > 0 ? selectedOrgObj.domain[item.domain] : true) &&
          (pl.length > 0 ? selectedOrgObj.pl[item.pl] : true) &&
          (subPl.length > 0 ? selectedOrgObj.subPl[item.subPl] : true)
        ) {
          return item;
        }
      });
      const orgData = {};
      orgTypes.forEach((item) => {
        orgData[item + "Ops"] = getChangedOrgOpt(orgs, item).sort();
      });
      return orgData;
    };
    const getChangedOrgOpt = (orgs, type) => {
      const orgOptList = [];
      removeDuplicate(orgs, type).forEach((item) => {
        orgOptList.push(item[type]);
      });
      return orgOptList;
    };
    const removeDuplicate = (arr, key) => {
      const res = new Map();
      return arr.filter(
        (arr) => !res.has(arr[key]) && res.set(arr[key], arr[key])
      );
    };

    watch(
      () => formState.createDateBeginList,
      (value) => {
        console.log(value);
        if (value) {
          formState.createDateBegin = value[0]
            ? value[0].format("YYYY-MM-DD")
            : null;
          formState.createDateEnd = value[1]
            ? value[1].format("YYYY-MM-DD")
            : null;
        }
      }
    );
    watch(
      () => formState.terminatedDateBeginList,
      (value) => {
        if (value) {
          formState.terminatedDateBegin = value[0]
            ? value[0].format("YYYY-MM-DD")
            : null;
          formState.terminatedDateEnd = value[1]
            ? value[1].format("YYYY-MM-DD")
            : null;
        }
      }
    );
    const getAccountOptions = async () => {
      await getAccountPLItems();
      await getAccountPLRelations();
      // 过滤掉下拉框内已经不存在的 account
      if (formState.account?.length > 0) {
        formState.account = formState.account.filter((item) =>
          paramListObj.accountOps.includes(item)
        );
        orgAccountPLChange("account", false);
      }
      if (formState.domain?.length > 0) {
        formState.domain = formState.domain.filter((item) =>
          paramListObj.domainOps.includes(item)
        );
        orgAccountPLChange("domain", false);
      }
    };
    const roles = computed(() => store.state.app.user.roles);
    const Operation = ref(roles.value.indexOf("1") !== -1);

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'basicDataManagement',
        secondMenu: 'account',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > formLength+1?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'pl':
          return paramListObj.plOps
        case 'subPl':
          return paramListObj.subPlOps
        case 'account':
          return paramListObj.accountOps
        case 'domain':
          return paramListObj.domainOps
        default :
          ''  // 如果没
      }
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      formState.account = route.query.id?[route.query.id]:[];
      doSearch();
      updateParams();
      getAccountOptions();
      // getAccountPLItems()
      // getAccountPLRelations()
      if (Operation.value) {
        getPlAndSubPlRelation();
        getAccountStatus();
      }
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    watch(
      () => route.query.id,
      () => {
        formState.account = route.query.id?[route.query.id]:[];
        doSearch();
      }
    );
    const openMergingClick = () => {
      newMergeDomianVisible.value = true;
    };
    const newMergeDomianVisible = ref(false);
    const handleSaveNewMergedDomian = (data) => {
      if (data === "save") {
        doSearch();
        getAccountPLItems();
      }
      newMergeDomianVisible.value = false;
    };
    const getTotal = (data) => {
      if (data === 0) {
        dataBoxShow.value = false;
        noDataShow.value = true;
      } else {
        dataBoxShow.value = true;
        noDataShow.value = false;
      }
    }
    return {
      downloadTemplate,
      accountOps,
      domainOps,
      plOps,
      subPlOps,
      addAccount,
      formState,
      resetForm,
      doSearch,
      drawerVisible,
      drawerType,
      accountListRef,
      drawerSelectOptions,
      accountStatusData,
      getAccountStatus,
      getStatus,
      openMergingModal,
      exportAccountData,
      updateParams,
      currentState,
      importAccountData,
      importModalVisible,
      handleCloseModal,
      optType,
      doUpdateImport,
      getAccountOptions,
      updateTableHeight,
      mergeDomianVisible,
      // handleOpenTbdModal,
      tbdCodeModalVisible,
      saveTbdCode,
      tbdInitData,
      isShowBtn,
      handleShowBtn,
      selectedState,
      handleSelectedDate,
      handleDeactive,
      handleActive,
      orgAccountPLChange,
      getAccountPLItems,
      getAccountPLRelations,
      paramListObj,
      getValueAndOps,
      initUnderData,
      getOrgOptData,
      getChangedOrgOpt,
      removeDuplicate,
      tbdCodeVisible,
      tbdCodeList,
      handleAddtbdcode,
      initialMergingData,
      allAccountOpts,
      allDomainOpts,
      handleSaveMergedDomian,
      getCaptiveAndTierList,
      deactiveData,
      getProjectCloseDate,
      deactiveModalVisible,
      saveDeactiveModal,
      doInsertImport,
      handleSelectedListDate,
      modalVisible,
      deactiveError,
      handleOk,
      handleCancel,
      Operation,
      openMergingClick,
      newMergeDomianVisible,
      handleSaveNewMergedDomian,
      dataBoxShow,
      noDataShow,
      modelValue,
      content,
      showIconCom,
      handleOk1,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      formLength,
      isShowMoreBtn,
      sortFlag,
      getTotal,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/titleTab.less";

.rm-staff-container {
  .tab {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
  }

  .app-container {
    .account-list {
      position: relative;
      .message {
        bottom: 15px;
        position: absolute;
        color: #6f6f6f;
        span {
          color: #b3b3b3;
        }
      }
      .message1 {
        color: #6f6f6f;
        span {
          color: #b3b3b3;
        }
      }
    }
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
:deep(.dropdown){
  .ant-dropdown-menu-item{
    color: #3182CE;
    &.ant-dropdown-menu-item-disabled{
      color: #ccc;
    }
    &:hover{
      background: #E9F6FE;
    }
    span.ant-dropdown-menu-title-content{
      display: flex;
      height: 100%;
      line-height: 36px;
    }
    .anticon{
      display: inline-block;
      width: 24px;
    }
  }
}

:deep(.icon-font){
  display: inline-block;
  color: #3182CE;
  &.red{
    color: #ff4d4f;
  }
  svg{
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
</style>
