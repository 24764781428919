<template>
  <div ref="selectAddModalRef">
    <a-modal
        :getContainer="()=>$refs.selectAddModalRef"
        :visible="visible"
        width="20.8vw"
        title="TBD CODE新增"
        @cancel="closeModal"
        class="selectTbdCodeModal"
        centered
    >
      <template #footer>
        <a-button @click="closeModal">取消</a-button>
        <a-button :disabled="selectAccountList.length === 0" type="primary" @click="handleAddtbdcode">
          确定
        </a-button>
      </template>
      <a-checkbox-group v-model:value="selectAccountList">
        <a-checkbox
            v-for="item in tbdCodeList"
            :value="item"
            :key="item.id"
            style="display:block"
        >
          {{item.account}} Account新增成功，如确定新建对应TBD Code请勾选
        </a-checkbox>
      </a-checkbox-group>
    </a-modal>
  </div>
</template>
<script>

import {reactive, ref} from "vue";
import moment from "moment/moment";

export default {
  name: "SelectAddModal",
  components: {},
  props: {
    visible:Boolean,
    tbdCodeList : {
      type:Array
    },
  },

  setup(props,{ emit }) {

    const selectAccountList = ref([])
    const handleSelectAccount = reactive([
      {
        id:'',
        account:'',
        createTime:'',
        engagementCode:'',
      }
    ])
    const closeModal = () => {
      emit("close-modal");
    }
    const handleAddtbdcode = () => {
      handleSelectAccount.value = JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(selectAccountList.value))))
      const selectAccount = handleSelectAccount.value.map(item => {
        return {
          id: item.id,
          account: item.account,
          createTime: moment(item.createTime).format("YYYY-MM-DD"),
          engagementCode: item.account + '_TBD',
        }
      })
      emit("handleAddtbdcode",selectAccount)
    }

    return {
      selectAccountList,
      closeModal,
      handleAddtbdcode,
      handleSelectAccount,
    };
  },
}
</script>

<style lang="less">
@import "~@/style/aModal.less";
.selectTbdCodeModal {
   width: 80vh;
  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 13px;
      height: 13px;
    }

    &::-webkit-scrollbar-thumb {
      width: 13px;
      height: 13px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }
  .ant-checkbox-wrapper{
    margin-left: 0;
  }
}
</style>
