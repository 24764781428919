<template>
  <div class="Two-MergeDomain main-padding">
    <div class="infos"><span>新Account:</span><span style="color: #3182CE;margin-left: 5px;">{{newAccount.oneMergingAccountSelect}}</span></div>
  <!--
item-key="id" 必传
v-model="myArray" 绑定在draggable的数组
-->
    <div class="Draggable-Box">
      <span v-for="item in selectedAccountData" :key="item" style="margin:10px 10px 0 0;">
        <DraggableBox
            :title="item.oldDomain"
            v-model="item.mergeDomain"
            :group="group"
            item-key="id"
            @start="data.drag=true"
            @end="data.drag=false"
        >
        </DraggableBox>
      </span>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, watch,onMounted} from "vue";
import DraggableBox from "@/components/DraggableBox";
import {useGlobalPropertyHook} from "@/hooks/common";

export default {
  name: "TwoMergeDomain",
  components: { DraggableBox },
  props: {
    selectedAccount: {
      type: Object,
      default: () => {},
    },
    current: {
      type: Number,
    },
    isgetDomain: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["mergeDomainData"],
  setup(props,{ emit }) {
    const { $api } = useGlobalPropertyHook();
    const selectedAccountData = ref([])

    const newAccount = computed(() => props.selectedAccount)

    const getDomainData = () => {
      $api.getDomain(props.selectedAccount.oldAccountIdList).then((res) => {
        selectedAccountData.value = res.data.map(item => {
          return {
            account:item.account,
            id: item.id,
            newDomain: item.domain.split(','),
            oldDomain:item.domain,
            newAccount: newAccount.value.oneMergingAccountSelect,
            terminatedDate: item.terminated_date,
            mergeDomain:[{account:item.account,newDomain:item.domain}]
          }
        })
      })
    }

    // watch(
    //     () => props.current,
    //     (val) => {
    //       debugger
    //       console.log(selectedAccountData.value);
    //       console.log(props.isgetDomain)
    //       if(val === 1 && props.isgetDomain === true ) getDomainData()
    //     },
    //     {immediate:true}
    // )

    watch(
        () => selectedAccountData.value,
        (val) => {
          if(val.length !== 0) {
            let a = selectedAccountData.value.map(item => {
              return {
                account: item.account,
                id: item.id,
                newDomain: selectedAccountData.value.filter(i => i.mergeDomain.map(item => item.newDomain).indexOf(item.oldDomain) !== -1)[0].oldDomain,
                oldDomain: item.oldDomain,
                newAccount: newAccount.value.oneMergingAccountSelect,
                terminatedDate: item.terminatedDate,
              }
            })
            console.log('1111', a)
            emit("mergeDomainData", a);
            localStorage.setItem('Account-Data',JSON.stringify(selectedAccountData.value))
          }
        },
        {deep:true,immediate:true}
    )

    const data = reactive({
      drag: false,
    })
    const group = ref({
      name: "itxst",
      pull: true,
      put: true,
    })

    onMounted(() => {
      if(props.current === 1 && props.isgetDomain === true ) {
        getDomainData()
      } else {
        selectedAccountData.value = JSON.parse(localStorage.getItem("Account-Data"));
      }
      console.log('111',selectedAccountData.value);
    });


    return {
      selectedAccountData,
      getDomainData,
      newAccount,
      data,
      group,
    };
  }
}
</script>

<style scoped lang="less">
.Two-MergeDomain{
  margin-bottom: 52px;
  .infos{
    margin: 24px 0 24px 0;
    .span {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #999999;
    }
  }
  .Draggable-Box{
    display: flex;
    flex-wrap: wrap;
    .span{
      margin:10px 10px 0 0;
      :deep(.draggableColor){
        :deep(.item){
          background-color: #59a4e1 !important;
        }
      }
    }
  }
}
.main-padding{
  padding: 0 24px;
}
</style>
