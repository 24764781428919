<template>
  <div class="Four-TerminatedDate main-padding">
    <div class="infos"><span>新Account:</span><span style="color: #3182CE;margin-left: 5px;">{{newAccount}}</span></div>
    <div>
      <a-table
          :columns="columns"
          :data-source="tableList"
          rowKey="arveNO"
          :scroll="{

          scrollToFirstRowOnChange: true,
        }"
          :pagination="false"
      >
        <template #Date="{ record }">
          <a-date-picker
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              class="item"
              allow-clear
              type="date"
              placeholder="选择日期"
              :disabledDate="current => disabledDate(current)"
              v-model:value="record.terminatedDate"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import moment from "moment/moment";

export default {
  name: "FourTerminatedDate",
  props: {
    terminatedDate: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["terminatedDateEmit"],
  setup(props,{ emit }){
    const columns = [
      {
        title: "新Domain",
        dataIndex: "newDomain",
        key: "newDomain",
        width: 100,
        ellipsis: true
      },
      {
        title: "Terminated Date",
        dataIndex: "terminatedDate",
        key: "terminatedDate",
        width: 300,
        ellipsis: true,
        slots: { customRender: "Date" },
      },
    ];
    const tableList = ref([]);

    const disabledDate = (current) => {
      return current < moment().subtract(1, 'day').endOf('day');
    }

    const newAccount = computed(() => props.terminatedDate[0].newAccount)

    watch(
        () => props.terminatedDate,
        (val) => {
          if(val) {
            tableList.value = props.terminatedDate.filter((item)=>{return item.newDomain === item.oldDomain})
            emit("terminatedDateEmit", tableList.value);
          }
        },
        {immediate:true}
    )
    watch(
        () => tableList.value,
        (val) => {
          if(val) {
            console.log(tableList.value)
            emit("terminatedDateEmit", tableList.value);
          }
        },
        {deep:true}
    )

    return {
      columns,
      tableList,
      disabledDate,
      newAccount,
    }
  }
}
</script>

<style scoped lang="less">
.Four-TerminatedDate{
  margin-bottom: 52px;
  .infos{
    margin: 24px 0 24px 0;
    .span {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #999999;
    }
  }
}
.main-padding{
  padding: 0 24px;
}
</style>
